<template>
  <v-container>
    <v-row dense>
      <v-col>
        <csv-import v-on:parse="importAllUsers($event)"></csv-import>
      </v-col>
    </v-row>
    <v-row v-if="importLog.length > 0">
      <v-col>
        <v-card class="pa-6">
          <v-textarea
            readonly
            :loading="!canExport"
            v-model="importLog"
            id="importLogText"
          >
          </v-textarea>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="canExport">
      <v-col>
        <v-btn color="primary" @click="downloadCsvResults()">
          Download Results
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import awsAuth from "../../cognito/aws-auth";
import Axious from "axios";
import CsvImport from "./CsvImport";

export default {
  components: {
    CsvImport,
  },
  data() {
    return {
      headers: [
        { text: "Username", value: "username" },
        { text: "Email", value: "email" },
        { text: "Company", value: "company" },
      ],
      csv: [],
      importLog: "",
      importList: [],
      totalImportCount: 0,
      poolUsers: [],
      importedUsers: [],
      canExport: false,
      importLogText: undefined,
    };
  },
  mounted() {
    this.importList = [];
    this.totalImportCount = 0;
    this.importLog = "";
    this.importedUsers = [];
    this.canExport = false;
  },
  methods: {
    log(text) {
      this.importLog += text;
    },
    logln(text) {
      this.importLog += text + "\n";
      if (this.importLogText == undefined)
        this.importLogText = document.getElementById("importLogText");
      if (this.importLogText != undefined) {
        setTimeout(() => {
          this.importLogText.scrollTop = this.importLogText.scrollHeight;
        }, 50);
      }
    },
    importAllUsers(users) {
      this.importList = users;
      this.totalImportCount = this.importList.length;
      this.importedUsers = [];
      this.importLog = "";
      this.log("Getting all the users...");
      awsAuth
        .getAllUsers()
        .then((result) => {
          this.logln("✅");
          this.poolUsers = result;
          this.importNextUser();
        })
        .catch((err) => {
          this.logln(`❌ ${err.status}: ${err.message}`);
        });
    },
    importNextUser() {
      if (this.importList.length == 0) {
        this.logln("🙌 All done!");
        this.canExport = true;
        return;
      }
      let user = this.importList.shift();
      this.importUser(user);
    },
    importUser(user) {
      if (
        user.email == undefined ||
        user.email == null ||
        !this.validateEmail(user.email)
      ) {
        this.logln(`💥 Invalid email: '${user.email}', skipping`);
        this.importNextUser();
        return;
      }

      // cleanup
      user.username = user.username.toLowerCase();

      this.importedUsers.push(user);
      this.logln(
        `[${this.importedUsers.length}/${this.totalImportCount}] Importing ${user.username}... `
      );

      if (this.poolUsers.find((x) => x.Username == user.username) != null) {
        this.log("👤 existing user! ");
        if (user.password != null && user.password != undefined) {
          this.logln("Password was set, skipping");
          this.importNextUser();
          return;
        }
        this.log("Changing password... ");
        user.password = this.generatePassword(12);
        awsAuth
          .updatePassword(user.username, user.password)
          .then(() => {
            this.logln(`✅`);
            this.confirmUser(user);
          })
          .catch((err) => {
            this.logln(`❌ ${err.status}: ${err.message}`);
          });
      } else {
        user.password = this.generatePassword(12);
        this.log("🆕 New user, signing up... ");
        awsAuth
          .signUp(user.username, user.password, user.email, user.company)
          .then(() => {
            this.logln(`✅`);
            this.confirmUser(user);
          })
          .catch((err) => {
            this.logln(`❌ ${err.status}: ${err.message}`);
          });
      }
    },
    confirmUser(user) {
      this.log(`Confirming user auth ${user.username}... `);
      awsAuth
        .adminConfirmUser(user.username)
        .then(() => {
          this.logln(`✅`);
          this.changeGroups(user);
        })
        .catch((err) => {
          this.logln(`❌ ${err.status}: ${err.message}`);
          this.changeGroups(user);
        });
    },
    changeGroups(user) {
      this.log(`Changing user groups ${user.username}... `);
      this.log(`Presenter... `);
      awsAuth
        .addUserToGroup(user.username, "Presenter")
        .then(() => {
          this.log(`✅ role registered... `);
          this.importNextUser();
        })
        .catch((err) => {
          this.logln(`❌ ${err.status}: ${err.message}`);
          this.importNextUser();
        });
    },
    generatePassword(length) {
      let charset =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
      var retVal = "";
      for (var i = 0, n = charset.length; i < length; ++i) {
        retVal += charset.charAt(Math.floor(Math.random() * n));
      }
      return retVal;
    },
    validateEmail(email) {
      const re = /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
    downloadCsvResults() {
      console.log("Here you go!");
      var resultCsv = "Username;Email;Company;Password\n";
      for (var i = 0; i < this.importedUsers.length; i++) {
        let user = this.importedUsers[i];
        resultCsv += `${user.username};${user.email};${user.company};${user.password}\n`;
      }
      console.log(resultCsv);

      Axious({
        url: "https://source.unsplash.com/random/500x500",
        method: "GET",
        responseType: "blob",
      }).then(() => {
        const url = window.URL.createObjectURL(new Blob(resultCsv.split("")));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "result.csv");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
    },
  },
};
</script>

<template>
<v-card class="pa-6">
    <v-card-title>Import users from CSV file</v-card-title>
    
    <v-file-input 
        v-model="csvFile"
        label="CSV File"
        accept="text/*" 
        @change="resetFlags()"
        />
    
    <v-container v-if="showSettings">
        <v-card-title><v-icon>{{ "fa fa-cogs" }}</v-icon> Settings</v-card-title>
        
        <v-chip-group>
            <template v-for="header in headers">
                <v-menu offset-y v-bind:key="header.source">
                    <template v-slot:activator="{ on, attrs }">
                        <v-chip 
                            v-bind="attrs"
                            v-on="on"
                        >
                            {{ header.source }} ➡  {{ header.skip ? "❌" : 
                                (header.dest == null ? "❔" : header.dest) }}
                        </v-chip>
                    </template>
                    <v-list>
                        <v-list-item
                            v-for="(dest, index) in dests"
                            :key="index">
                            <v-btn text @click="header.dest=dest.title; header.skip=dest.skip;">{{ dest.title }}</v-btn>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </template>                
        </v-chip-group>
    </v-container>

    <v-card-actions>
        <v-btn
            color="primary"
            width="150px"
            @click="parseFile"
            :disabled="csvFile==null || importOnce || uploadOnce"
        >
           Upload CSV File
        </v-btn>
        <v-btn
            color="primary"
            width="150px"
            @click="importFile()"
            :disabled="headers.length==0 || importOnce"
        >
           Import CSV File
        </v-btn>
    </v-card-actions>
</v-card>
</template>

<script>
export default {
    data() {
        return {
            showSettings: false,
            csvFile: null,
            fileLines: [],
            dests: [
                { title: "username" },
                { title: "email" },
                { title: "company" },
                { title: "password" },
                { title: "❌ don't import", skip: true }
            ],
            headers: [],
            uploadOnce: false,
            importOnce: false
        }
    },
    mounted() {       
        // Test data
        /*this.headers = [
            { source: "IDLE", dest: "username" },
            { source: "MASTER", dest: "null", skip: true },
            { source: "EMAIL", dest: "email" },
            { source: "COMP", dest: "company" }
        ];
        this.fileLines = ["IDLE;EMAIL;MASTER;COMP","vasya;vasya@test.com;foo;Icvr","lenas;elenap@icvr.io;skipit!;Unknown"];\
        */
    },
    methods: {
        resetFlags() {
            this.uploadOnce = false;
            this.importOnce = false;
        },
        parseFile() {
            console.log(`Parsing '${this.csvFile}'`);
            this.uploadOnce = true;
            this.csvFile
                .text()
                .then((fileContent) => {
                    fileContent = fileContent.replace('\r', '');
                    this.fileLines = fileContent.split('\n');
                    if (this.fileLines.length == 0) return;
                    this.showSettings = true;
                    let headers = this.fileLines[0].split(";")
                    this.headers = [];
                    for (var i = 0; i < headers.length; i++)
                    {
                        let header = headers[i];
                        if (header == undefined || header.length == 0) continue;
                        let autoMatch = this.dests.find(x => x.title.toLowerCase() == header.toLowerCase());
                        this.headers.push({
                            source: header,
                            dest: autoMatch != undefined ? autoMatch.title : null
                        })
                    }
                });
        },
        importFile() {      
            this.importOnce = true;      
            let importSetup = {};
            for (var i = 0; i < this.headers.length; i++) {
                let header = this.headers[i];
                if (header.skip || header.dest == null || header.dest == undefined) continue;
                importSetup[header.source] = header.dest;
            }
            let headerLine = this.fileLines[0].split(';');
            let data = [];
            for (i = 1; i < this.fileLines.length; i++) {
                let fields = this.fileLines[i].split(';');
                let line = {};
                for (var j = 0; j < fields.length; j++) {
                    let headerName = headerLine[j];
                    if (importSetup[headerName] == undefined) continue;                    
                    line[importSetup[headerName]] = fields[j].replace("\r","");
                }               
                data.push(line);
            }
            console.table(data);
            this.$emit('parse', data);
        }
    }
}
</script>
